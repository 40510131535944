@import 'src/style/mixins';

.navigation {
  @media screen and (max-width: 992px) {
    @include ease(0.3s);
    &.open {
      aside {
        z-index: 999;
      }
      section.ant-layout {
        z-index: 1;
        position: absolute;
        width: 100%;
        left: 0;
      }
    }
  }
}

.language-select {
  padding-left: 12px;
  .ant-select-selector {
    color: rgba(255, 255, 255, 0.75) !important;
    background-color: transparent !important;
    border-color: transparent !important;
  }
  .ant-select-arrow {
    color: rgba(255, 255, 255, 0.75) !important;
  }
}

.nav-divider {
  border-color: rgba(255, 255, 255, 0.75) !important;
}
