.gallery-slide {
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
  .img-wrapper {
    max-width: 100%;
    display: flex;
    justify-content: center;
    img {
      max-width: 100%;
      max-height: 80vh;
      object-fit: contain;
    }
  }
  .info-panel {
    width: 350px;
  }
}

.gallery-carousel-controls {
  li {
    padding: 8px;
    border: 1px solid #fff;
    button {
      background-color: #001529 !important;
    }
  }
}
