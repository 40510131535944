.map-marker {
  height: 2rem;
  width: 2rem;
  border-radius: 100px;
}

.blob {
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  margin: 10px;
  height: 1.25rem;
  width: 1.25rem;
  transform: scale(1.35);
  animation: pulse-black 2s infinite;
}

.blob.red {
  background: rgba(255, 82, 82, 1);
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: pulse-red 2s infinite;
  border: 2px solid white;
}

@keyframes pulse-red {
  0% {
    transform: scale(1.3);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.9);
  }

  70% {
    transform: scale(1.35);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(1.3);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.gage-reading-chart-wrapper {
  position: relative;
  max-width: 100%;
  overflow-x: scroll;
}
