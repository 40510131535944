@import '~leaflet/dist/leaflet.css'; // sass
@import '~react-leaflet-markercluster/dist/styles.min.css'; // sass

.leaflet-container {
  width: 'max-content';
  height: 40vh;
}

// charts

.prophet-chart {
  &.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 250px;
    width: 100%;
  }
  .loading-content {
    display: flex;
    :nth-child(2) {
      margin-left: 1rem;
    }
  }
}
